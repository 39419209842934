import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const AutoLink = React.forwardRef(function AutoLink(
  { href, to, target, ...rest },
  ref
) {
  const url = to || href

  return target || !url.startsWith("/") || /\.[a-z0-9]{1,5}$/.test(url) ? (
    <a
      ref={ref}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    />
  ) : (
    <Link ref={ref} to={url} {...rest} />
  )
})

export default AutoLink

AutoLink.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
}
