import { darken } from "color2k"
import AutoLink from "components/AutoLink"
import InvisibleButton from "components/InvisibleButton"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"
import theme from "theme"

/**
 * A customizable button component that can be rendered as a link or a button.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.href] - The URL to link to if the button should be a link.
 * @param {string} [props.color] - The text color of the button.
 * @param {string} [props.backgroundColor] - The background color of the button.
 * @param {string} [props.borderColor] - The border color of the button.
 * @param {string} [props.outlineColor] - The outline color when the button is focused.
 * @param {Object} [props.whileHover] - Styles to apply when the button is hovered.
 * @param {string} [props.whileHover.color] - The text color on hover.
 * @param {string} [props.whileHover.backgroundColor] - The background color on hover.
 * @param {string} [props.whileHover.borderColor] - The border color on hover.
 * @param {Object} [props.whileTap] - Styles to apply when the button is tapped/clicked.
 * @param {string} [props.whileTap.color] - The text color on tap/click.
 * @param {string} [props.whileTap.backgroundColor] - The background color on tap/click.
 * @param {string} [props.whileTap.borderColor] - The border color on tap/click.
 * @returns {React.Component} A styled button or link component.
 */
const Button = forwardRef(function Button(
  {
    backgroundColor,
    borderColor,
    color,
    href,
    outlineColor,
    whileHover,
    whileTap,
    ...rest
  },
  ref,
) {
  // Wrap the potential link in a motion component to allow for hover and tap effects
  const ButtonComponent = href
    ? motion.create(AutoLink)
    : motion.create(InvisibleButton)

  // Set the initial styles
  const initialBackgroundColor = backgroundColor || theme.stampRed
  const initialBorderColor = borderColor || backgroundColor || theme.stampRed
  const initialColor = color || theme.tan
  const initialOutlineColor = outlineColor || theme.stampRed

  // Set the styles for when the button is hovered
  const whileHoverBackgroundColor = whileHover?.backgroundColor
    ? whileHover.backgroundColor
    : darken(initialBackgroundColor, 0.05)
  const whileHoverBorderColor = whileHover?.borderColor
    ? whileHover.borderColor
    : whileHoverBackgroundColor
  const whileHoverColor = whileHover?.color ? whileHover.color : initialColor

  // Set the styles for when the button is tapped/clicked
  const whileTapBackgroundColor = whileTap?.backgroundColor
    ? whileTap.backgroundColor
    : darken(initialBackgroundColor, 0.1)
  const whileTapBorderColor = whileTap?.borderColor
    ? whileTap.borderColor
    : whileTapBackgroundColor
  const whileTapColor = whileTap?.color ? whileTap.color : initialColor

  return (
    <ButtonComponent
      {...rest}
      ref={ref}
      href={href}
      css={{
        ...theme.h3,
        position: "relative",
        fontWeight: 800,
        background: initialBackgroundColor,
        color: initialColor,
        border: "1px solid",
        borderColor: initialBorderColor,
        borderRadius: 0,
        padding: "20px 30px",
        lineHeight: 1,
        letterSpacing: "0.1em",
        outline: "none",
        [theme.mobile]: {
          padding: "15px 30px",
        },
        "&::after": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
        "&:focus-visible": {
          "&::after": {
            outlineWidth: 3,
            outlineOffset: 1,
            outlineStyle: "solid",
            outlineColor: initialOutlineColor,
            opacity: 0.5,
          },
        },
      }}
      whileHover={{
        color: whileHoverColor,
        backgroundColor: whileHoverBackgroundColor,
        borderColor: whileHoverBorderColor,
      }}
      whileTap={{
        color: whileTapColor,
        backgroundColor: whileTapBackgroundColor,
        borderColor: whileTapBorderColor,
      }}
    />
  )
})

export default Button

Button.propTypes = {
  href: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  outlineColor: PropTypes.string,
  whileHover: PropTypes.shape({
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  whileTap: PropTypes.shape({
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
  }),
}
