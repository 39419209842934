import { useState, useCallback, useEffect } from "react"

/**
 * Similar to `useState` but with some lightweight behind-the-scenes
 * writing to localStorage; also subscribes to changes in localStorage
 *
 * @param {string} key The string key name to be written to localStorage
 * @param {object} options
 * @param {*} options.initialValue Initial value for setState
 * @param {boolean} options.bool Treat values as boolean types
 * @returns {*} Stored value
 */
const useStorage = (key, { initialValue, bool }) => {
  const [value, setValue] = useState(initialValue)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const rawValue = window.localStorage.getItem(key)
    if (rawValue != null) {
      setValue(parseValue(rawValue, { asBoolean: bool }))
    }
    setIsLoading(false)

    const handleChanges = e => {
      if (e.key === key) {
        setValue(parseValue(e.newValue, { asBoolean: bool }))
      }
    }

    window.addEventListener("storage", handleChanges)

    return () => {
      window.removeEventListener("storage", handleChanges)
    }
  }, [key, bool])

  const updater = useCallback(
    newValue => {
      setValue(newValue)
      window.localStorage.setItem(key, JSON.stringify(newValue))
    },
    [key]
  )

  return [value, updater, isLoading]
}

export default useStorage

const parseValue = (value, options) => {
  if (options.asBoolean) {
    return value === "true" || value === "1"
      ? true
      : value === "false" || value === "0"
      ? false
      : value
  } else {
    return JSON.parse(value)
  }
}
