exports.components = {
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-store-locator-jsx": () => import("./../../../src/pages/store-locator.jsx" /* webpackChunkName: "component---src-pages-store-locator-jsx" */),
  "component---src-pages-strains-jsx": () => import("./../../../src/pages/strains.jsx" /* webpackChunkName: "component---src-pages-strains-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-lego-page-jsx": () => import("./../../../src/templates/LegoPage.jsx" /* webpackChunkName: "component---src-templates-lego-page-jsx" */),
  "component---src-templates-product-type-page-jsx": () => import("./../../../src/templates/ProductTypePage.jsx" /* webpackChunkName: "component---src-templates-product-type-page-jsx" */),
  "component---src-templates-strain-page-jsx": () => import("./../../../src/templates/StrainPage.jsx" /* webpackChunkName: "component---src-templates-strain-page-jsx" */)
}

