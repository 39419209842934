import { transparentize } from "color2k"
import Button from "components/Button"
import { getDaysInMonth, isAfter, subYears } from "date-fns"
import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"
import theme from "theme"

export const AgeGateForm = ({ onSubmit }) => {
  const [day, setDay] = useState("")
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")

  const months = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    10: "October",
    11: "November",
    12: "December",
  }

  const daysInMonth = useMemo(() => {
    if (month && year) {
      return getDaysInMonth(new Date(year, parseInt(month) - 1))
    }
    if (month) {
      return getDaysInMonth(
        new Date(new Date().getFullYear(), parseInt(month) - 1),
      )
    }
    return getDaysInMonth(new Date(new Date().getFullYear(), 0))
  }, [month, year])

  const handleSubmit = e => {
    e.preventDefault()
    const birthDate = new Date(year, parseInt(month) - 1, day)
    const minAgeDate = subYears(new Date(), 21)
    onSubmit(isAfter(minAgeDate, birthDate))
  }

  return (
    <form
      onSubmit={handleSubmit}
      css={{ display: "flex", flexDirection: "column", gap: 15 }}
    >
      <div
        css={{
          display: "flex",
          gap: 15,
          [theme.mobileSmall]: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
          },
        }}
      >
        <select
          value={month}
          onChange={e => setMonth(e.target.value)}
          required
          css={{
            ...theme.h4,
            height: 48,
            paddingInline: 20,
            paddingBlock: 0,
            background: "transparent",
            color: theme.tan,
            border: "1px solid",
            borderColor: theme.tan,
            borderRadius: 0,
            textAlignLast: "center",
            MozAppearance: "none",
            WebkitAppearance: "none",
            appearance: "none",
            "&:invalid": {
              color: transparentize(theme.tan, 0.5),
            },
            "&:focus": {
              outline: "3px solid",
              outlineColor: transparentize(theme.tan, 0.75),
              outlineOffset: 1,
            },
          }}
        >
          <option value="" disabled hidden>
            Month
          </option>
          {Object.entries(months)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
        </select>
        <select
          value={day}
          onChange={e => setDay(e.target.value)}
          required
          css={{
            ...theme.h4,
            height: 48,
            paddingInline: 20,
            paddingBlock: 0,
            background: "transparent",
            color: theme.tan,
            border: "1px solid",
            borderColor: theme.tan,
            borderRadius: 0,
            textAlignLast: "center",
            appearance: "none",
            WebkitAppearance: "none",
            MozAppearance: "none",
            "&:invalid": {
              color: transparentize(theme.tan, 0.5),
            },
            "&:focus": {
              outline: "3px solid",
              outlineColor: transparentize(theme.tan, 0.75),
              outlineOffset: 1,
            },
          }}
        >
          <option value="" disabled hidden>
            Day
          </option>
          {Array.from({ length: daysInMonth }, (_, i) => (
            <option key={i} value={i + 1}>
              {(i + 1).toString().padStart(2, "0")}
            </option>
          ))}
        </select>
        <input
          type="number"
          value={year}
          onChange={e => setYear(e.target.value)}
          placeholder="Year"
          min="1900"
          max={new Date().getFullYear()}
          required
          css={{
            ...theme.h4,
            width: 128,
            height: 48,
            paddingInline: 20,
            paddingBlock: 0,
            background: "transparent",
            color: theme.tan,
            border: "1px solid",
            borderColor: theme.tan,
            borderRadius: 0,
            textAlign: "center",
            appearance: "none",
            WebkitAppearance: "none",
            MozAppearance: "textfield",
            "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
            "&::placeholder": {
              color: transparentize(theme.tan, 0.5),
            },
            "&:focus": {
              outline: "3px solid",
              outlineColor: transparentize(theme.tan, 0.75),
              outlineOffset: 1,
            },
          }}
        />
      </div>
      <Button type="submit">Enter</Button>
    </form>
  )
}

AgeGateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
