import { motion } from "framer-motion"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"
import theme from "theme"

/**
 * @param {boolean|null} confirmed - Whether the user has confirmed their age
 * @param {function} onComplete - Callback function to be called when the animation completes
 */
export const AgeGateParallaxScene = ({ confirmed, onComplete }) => {
  return (
    <motion.div
      initial="initial"
      animate={confirmed ? "animate" : "initial"}
      onAnimationComplete={onComplete}
      css={{
        position: "relative",
        width: "100vw",
        height: "100lvh",
        overflow: "hidden",
      }}
    >
      <ParallaxLayer
        name="sky"
        lgStart={0}
        lgEnd={-790}
        smStart={0}
        smEnd={-701}
      >
        <StaticImage
          src="../images/age-gate/Scene_Age__0009_1-Sky.png"
          alt=""
          css={{
            width: "100%",
            pointerEvents: "none",
            userSelect: "none",
          }}
          sizes="100vw"
          draggable={false}
          layout="fullWidth"
          placeholder="blurred"
        />
      </ParallaxLayer>
      <ParallaxLayer
        name="hills-1"
        lgStart={914}
        lgEnd={104}
        smStart={734}
        smEnd={154}
      >
        <StaticImage
          src="../images/age-gate/Scene_Age__0008_2-Hills.png"
          alt=""
          css={{
            width: "100%",
            pointerEvents: "none",
            userSelect: "none",
          }}
          sizes="100vw"
          draggable={false}
          layout="fullWidth"
          placeholder="blurred"
        />
      </ParallaxLayer>
      <ParallaxLayer
        name="hills-2"
        lgStart={954}
        lgEnd={101}
        smStart={754}
        smEnd={151}
      >
        <StaticImage
          src="../images/age-gate/Scene_Age__0007_3-Hills.png"
          alt=""
          css={{
            width: "100%",
            pointerEvents: "none",
            userSelect: "none",
          }}
          sizes="100vw"
          draggable={false}
          layout="fullWidth"
          placeholder="blurred"
        />
      </ParallaxLayer>
      <ParallaxLayer
        name="ridge"
        lgStart={87}
        lgEnd={-900}
        smStart={107}
        smEnd={-500}
      >
        <StaticImage
          src="../images/age-gate/Scene_Age__0006_4-Ridge.png"
          alt=""
          css={{
            width: "100%",
            pointerEvents: "none",
            userSelect: "none",
          }}
          sizes="100vw"
          draggable={false}
          layout="fullWidth"
          placeholder="blurred"
        />
      </ParallaxLayer>
      <ParallaxLayer
        name="shrubs"
        lgStart={1230}
        lgEnd={130}
        smStart={900}
        smEnd={400}
      >
        <StaticImage
          src="../images/age-gate/Scene_Age__0005_5-Shrubs.png"
          alt=""
          css={{
            width: "100%",
            pointerEvents: "none",
            userSelect: "none",
          }}
          sizes="100vw"
          draggable={false}
          layout="fullWidth"
          placeholder="blurred"
        />
      </ParallaxLayer>
      <ParallaxLayer
        name="timothy"
        lgStart={1660}
        lgEnd={110}
        smStart={1260}
        smEnd={210}
      >
        <StaticImage
          src="../images/age-gate/Scene_Age__0002_Timothy_Smoking_01.png"
          alt=""
          css={{
            width: "100%",
            maxWidth: "36.38%",
            pointerEvents: "none",
            userSelect: "none",
          }}
          sizes="(min-width: 1200px) 36.38vw, 100vw"
          draggable={false}
          layout="fullWidth"
          placeholder="blurred"
        />
      </ParallaxLayer>
      <ParallaxLayer
        name="bush"
        lgStart={2281}
        lgEnd={500}
        smStart={2281}
        smEnd={450}
      >
        <StaticImage
          src="../images/age-gate/Scene_Age__0000_Bush_03.png"
          alt=""
          css={{
            width: "100%",
            pointerEvents: "none",
            userSelect: "none",
          }}
          sizes="100vw"
          draggable={false}
          layout="fullWidth"
          placeholder="blurred"
        />
      </ParallaxLayer>
      <div
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 19,
          background: "hsla(192, 100%, 12%, 1)",
          opacity: 0.5,
          pointerEvents: "none",
        }}
      />
    </motion.div>
  )
}

AgeGateParallaxScene.propTypes = {
  confirmed: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
}

/**
 * @param {number} amount - The value to calculate the offset for
 * @returns {string}
 */
function offsetCalc(amount) {
  // Define initial values based on the design specs
  const initialViewportHeight = 1024 // The height of the original viewport in the design
  const relativePositionPercentage = (amount / initialViewportHeight) * 100

  // Round to 3 decimal places without string conversion
  const roundedPercentage = Math.round(relativePositionPercentage * 1000) / 1000

  // Remove trailing zeros
  const trimmedPercentage = parseFloat(roundedPercentage.toString())

  // Return the calculated value using lvh units
  return `${trimmedPercentage}lvh`
}

const ParallaxLayer = ({ children, name, lgStart, lgEnd, smStart, smEnd }) => {
  const lgStartY = offsetCalc(lgStart)
  const lgEndY = offsetCalc(lgEnd)
  const smStartY = offsetCalc(smStart)
  const smEndY = offsetCalc(smEnd)

  return (
    <motion.div
      data-layer={name}
      css={{
        "--scale": 1,
        "--start-y": lgStartY,
        "--end-y": lgEndY,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.max(1200)]: {
          "--scale": 1.25,
        },
        [theme.mobile]: {
          "--scale": 2,
          "--start-y": smStartY,
          "--end-y": smEndY,
        },
      }}
      variants={{
        initial: {
          y: "var(--start-y)",
          scale: "var(--scale)",
          originY: 0,
        },
        animate: {
          y: "var(--end-y)",
          scale: "var(--scale)",
          originY: 0,
        },
      }}
      transition={{
        duration: 2.5,
      }}
    >
      {children}
    </motion.div>
  )
}

ParallaxLayer.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  lgStart: PropTypes.number.isRequired,
  lgEnd: PropTypes.number.isRequired,
  smStart: PropTypes.number.isRequired,
  smEnd: PropTypes.number.isRequired,
}
