import Wordmark from "components/Wordmark"
import FocusTrap from "focus-trap-react"
import { AnimatePresence, LayoutGroup, motion, useAnimate } from "framer-motion"
import useStorage from "hooks/useStorage"
import PropTypes from "prop-types"
import React, { createContext, useEffect, useState } from "react"
import ScrollLock from "react-scrolllock"
import theme from "theme"

import { AgeGateForm } from "./AgeGateForm"
import { AgeGateParallaxScene } from "./AgeGateParallaxScene"

export const AgeGateContext = createContext({
  pageReady: false,
  confirmed: null,
})

const AgeGate = ({ children }) => {
  const [confirmed, setConfirmed, isLoading] = useStorage("confirmed-age", {
    initialValue: null,
    bool: true,
  })

  const [playParallax, setPlayParallax] = useState(false)
  const [pageReset, setPageReset] = useState(false)
  const [pageReady, setPageReady] = useState(confirmed === null ? false : true)
  const [page, animatePage] = useAnimate()

  useEffect(() => {
    if (!isLoading && confirmed === true) {
      setPageReady(true)
    }
  }, [confirmed, isLoading])

  const onPageAnimationComplete = async () => {
    setPageReady(true)
    setConfirmed(true)
  }

  const onParallaxComplete = async () => {
    await window.scrollTo(0, 0)
    setPageReset(true)

    await animatePage(
      page.current,
      { y: 0 },
      {
        delay: 2,
        duration: 0.75,
        onComplete: onPageAnimationComplete,
      },
    )
  }

  const handleConfirmation = isConfirmed => {
    if (isConfirmed === false) {
      setConfirmed(false)
    }
    if (isConfirmed) {
      setPlayParallax(true)
    }
  }

  // Only render content when loading is complete
  if (isLoading) {
    return null // or a loading indicator if preferred
  }

  return (
    <AgeGateContext.Provider value={{ pageReady, confirmed }}>
      <AnimatePresence initial={false}>
        {confirmed !== true && (
          <FocusTrap active={true}>
            <motion.div
              css={{
                position: "fixed",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                overflow: "hidden",
                background: "#779B4B",
              }}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{
                duration: 0,
              }}
            >
              <AgeGateParallaxScene
                confirmed={playParallax}
                onComplete={onParallaxComplete}
              />
              <ScrollLock accountForScrollbars>
                <div
                  css={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    zIndex: 20,
                    overflow: "hidden",
                  }}
                >
                  <AnimatePresence>
                    {!playParallax && (
                      <LayoutGroup id="age-gate">
                        <motion.div
                          css={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "100%",
                            padding: "75px 30px",
                            textAlign: "center",
                            [theme.mobile]: {
                              padding: "50px 24px 75px",
                            },
                          }}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          <Wordmark
                            css={{
                              width: 360,
                              flex: "0 0 auto",
                              marginBottom: "min(140px, 10vh)",
                              [theme.mobile]: {
                                maxWidth: "67vw",
                                marginBottom: "10vh",
                              },
                            }}
                          />
                          {confirmed === null ? (
                            <>
                              <div
                                css={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  marginBottom: 25,
                                  color: theme.tan,
                                }}
                              >
                                <h2 css={{ ...theme.h4 }}>
                                  Are you over the&nbsp;age&nbsp;of&nbsp;21?
                                </h2>
                                <p
                                  css={{
                                    ...theme.t3,
                                    marginTop: 10,
                                    marginBottom: 0,
                                  }}
                                >
                                  You must select your birth date
                                  before&nbsp;entering&nbsp;the&nbsp;site.
                                </p>
                              </div>
                              <AgeGateForm onSubmit={handleConfirmation} />
                            </>
                          ) : (
                            <h2
                              css={{
                                ...theme.h2,
                                color: theme.tan,
                                marginBottom: 44,
                                maxWidth: 600,
                              }}
                            >
                              You have to be over the age of 21 to view
                              this&nbsp;site
                            </h2>
                          )}
                        </motion.div>
                      </LayoutGroup>
                    )}
                  </AnimatePresence>
                </div>
              </ScrollLock>
            </motion.div>
          </FocusTrap>
        )}
      </AnimatePresence>
      <motion.div
        ref={page}
        css={{
          position: "relative",
        }}
        initial={{
          y: confirmed === true ? 0 : "100lvh",
        }}
        animate={{
          y: pageReady || confirmed === true ? 0 : "100lvh",
        }}
        style={{
          opacity: pageReset || confirmed === true ? 1 : 0,
          pointerEvents: pageReady || confirmed === true ? "auto" : "none",
          overflow: pageReady || confirmed === true ? "visible" : "hidden",
        }}
      >
        {children}
      </motion.div>
    </AgeGateContext.Provider>
  )
}

AgeGate.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AgeGate
